import _ from 'lodash';
import { collection, doc, getDoc, getDocs, addDoc, setDoc } from 'firebase/firestore';
import { auth, db } from '../utils/firebase';
import { generateIntakeUrl, convertNumberToMonth, formatPhoneNumberForDb } from './utils';

async function getPatientByUid(uid) {
  try {
    const docRef = doc(db, 'customers', uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      console.log('No such document!');
      return;
    }
  } catch (error) {
    console.error('Error getting patient by uid:', error);
  }
}

// get all customer from meetings subcollection of customers collection
async function getPatientMeetings(uid) {
  try {
    const docRef = doc(db, 'customers', uid);
    const meetingsCollectionRef = collection(docRef, 'meetings');
    const meetingsSnapshot = await getDocs(meetingsCollectionRef);

    return meetingsSnapshot.docs.map(doc => doc.data());
  } catch (e) {
    console.log('Error getting patient meetings:', e);
    return [];
  }
}

async function addAppointmentToPatient(uid, appointmentData) {
  try {
    const custDocRef = doc(db, 'customers', uid);
    const appointmentCollectionRef = collection(custDocRef, 'appointments');
    const data = {
      start: appointmentData.start,
      end: appointmentData.end,
      doctor: appointmentData.doctor,
      doctorName: appointmentData.doctorName,
      meetingType: appointmentData.meetingType,
      created_at_ts: new Date().getTime(),
    };

    const appointmentDocRef = await addDoc(appointmentCollectionRef, data);

    return appointmentDocRef;
  } catch (e) {
    console.error('Error adding appointment to patient:', e);
  }
};

async function updatePatientData(uid, data) {
  try {
    const setData = {
      uid: uid,
      ...data.emailForm && { emailForm: data.emailForm.toLowerCase()},
      ...data.DOBm && { DOBFull: `${data.DOBm}-${data.DOBd}-${data.DOBy}` },
      ...data.DOBd && { DOBd: data.DOBd},
      ...data.DOBm && { DOBm: data.DOBm},
      ...data.DOBy && { DOBy: data.DOBy},
      ...data.firstName && { firstName: _.capitalize(data.firstName)} ,
      ...data.lastName && { lastName: _.capitalize(data.lastName) },
      ...data.intakeUrl && { intakeUrl: data.intakeUrl},
      ...data.address && { address1: data.address},
      ...data.city && {city: data.city},
      ...data.state && { state: data.state},
      ...data.zipCode && { zipCode: data.zipCode},
      ...data.meeting && { meeting: data.meeting } , // start
      ...data.meetNow && { meet_now: data.meetNow},
      ...data.meetNowTs && { meet_now_ts: data.meetNowTs},
      ...data.id && { id: data.id},
      ...data.phoneNumber && { phoneNumber: formatPhoneNumberForDb(data.phoneNumber)},
      ...data.provider && { provider: data.provider},
    };

    console.log('setData', setData);

    const docRef = doc(db, 'customers', uid);
    await setDoc(docRef, setData, { merge: true });
  } catch (error) {
    console.error('Error updating patient data:', error);
  }
}

export {
  getPatientByUid,
  getPatientMeetings,
  addAppointmentToPatient,
  updatePatientData,
};
