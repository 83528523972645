// AuthContext.jsx
import React, { createContext, useState, useEffect, useContext } from 'react';
import { auth, db } from './utils/firebase';
import { getDetailsByUid, isNewNewPatient } from './utils/utils';
import { getPatientByUid, updatePatientData } from './utils/firebase_api';
import { getPatientMeetings } from './utils/firebase_api';
import axios from 'axios';
import moment from 'moment';
import { getDrFromDxOrDr, maybeNavigateToFollowUp, maybeNavigateToSelectFollowUpType } from './utils/utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { functions } from './utils/firebase';
import { httpsCallable } from 'firebase/functions';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

// function useQuery() {
//   return new URLSearchParams(useLocation().search);
// }

export const AuthProvider = ({ children }) => {
  const location = useLocation();

  const [currentUser, setCurrentUser] = useState(null);
  const [patientDetails, setPatientDetails] = useState(null);
  const [patientData, setPatientData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [navigateToFollowup, setNavigateToFollowup] = useState(false);
  const [navigateToSelectFollowupType, setNavigateToSelectFollowupType] = useState(false);
  const [patientIsNewNew, setPatientIsNewNew] = useState(false);

  // const query = useQuery();

  // The cookie is written in the redirect from a new meeting with magic id
  const getCookie = name => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  };

  useEffect(() => {

    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setCurrentUser(user);  // Store the full user object, including uid
      console.log('user', user);

      if (user) {
        const uid = user.uid;

        if (await isNewNewPatient(uid)) {
          setPatientIsNewNew(true);
        }

        try {
          await updatePatientData(uid, {
            id: user.providerData[0].uid,
            provider: user.providerData[0].providerId,
            phoneNumber: user.providerData[0].phoneNumber,
            uid: uid,
          });
        } catch (e) {
          console.error('Error updating patient data:', e);
        }

        try {
          const data = await getPatientByUid(uid || {});
          const res = await httpsCallable(functions, 'getDoctorIdForFollowup')();
          const doctorId = res?.data?.doctor_id;

          if (doctorId) {
            data.doctor_id = doctorId;
          }
          setPatientData(data);

          // run getPatientByUid again in 2 seconds
          if (!data.magic_id) {
            setTimeout(async () => {
              const data = await getPatientByUid(uid || {});
              const res = await httpsCallable(functions, 'getDoctorIdForFollowup')();
              const doctorId = res?.data?.doctor_id;

              if (doctorId) {
                data.doctor_id = doctorId;
              }

              setPatientData(data);

              if (await maybeNavigateToFollowUp(user, location)) {
                if (await maybeNavigateToSelectFollowUpType(data, location)) {
                  console.log('navigating to select follow up type')
                  setNavigateToSelectFollowupType(true);
                } else {
                  console.log('navigating to followup')
                  setNavigateToFollowup(true);
                }
              } else if (await maybeNavigateToSelectFollowUpType(data, location)) {
                console.log('navigating to select follow up type')
                setNavigateToSelectFollowupType(true)
              }
            }, 2000);
          } else {
            console.log('checking maybe navigate')
            console.log('data', data);

            if (await maybeNavigateToFollowUp(user, location)) {
              if (await maybeNavigateToSelectFollowUpType(data, location)) {
                console.log('navigating to select follow up type')
                setNavigateToSelectFollowupType(true);
              } else {
                console.log('navigating to followup')
                setNavigateToFollowup(true);
              }
            } else if (await maybeNavigateToSelectFollowUpType(data, location)) {
              console.log('navigating to select follow up type')
              setNavigateToSelectFollowupType(true)
            }
          }

          console.log('data', data);
        } catch (e) {
          console.error('Error getting patient by uid:', e);
        }

        // Early set is loading because getDetailsByUid can take 3 seconds
        setIsLoading(false);


        try {
          const details = await getDetailsByUid(uid);
          setPatientDetails(details || {});
          console.log('details', details);
        } catch (e) {
          console.error('Error getting details by uid:', e);
        }

      }

      setIsLoading(false);

    });

    let tkn = getCookie('token');
    if (tkn) {
      auth.signInWithCustomToken(tkn);
      // document.cookie = 'token=;';
    };

    return unsubscribe; // Clean up the listener on component unmount
  }, []);

  return (
    <AuthContext.Provider value={{
      currentUser,
      patientDetails,
      patientData,
      isLoading,
      navigateToFollowup,
      navigateToSelectFollowupType,
      setNavigateToSelectFollowupType,
      setNavigateToFollowup,
      patientIsNewNew,
    }}>
      {children}
    </AuthContext.Provider>
  );
};
