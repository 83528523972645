import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import axios from 'axios';
import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getStateFullName,
  getMeetingTypeFullName,
  formatPhoneNumber,
  isValidEmail,
  isValidPhoneNumber,
  generateIntakeUrl,
  checkIfEmailExists,
  setSessionStorageWithExpiry,
  registerMixpanelEvent,
} from '../utils/utils';
import stateCodeToName from '../utils/state_code_to_name.json';
import TextInput from './TextInput';
import DateOfBirthInput from './DateOfBirthInput';
import AddressInput from './AddressInput';
import PhoneInput from './PhoneInput';
import DropdownInput from './DropdownInput';
import Alert from './Alert';
import { auth, db } from '../utils/firebase';
import { getPatientByUid, addAppointmentToPatient, updatePatientData } from '../utils/firebase_api';
import { ReactComponent as GlobeIcon } from '../assets/globe.svg';
import { ReactComponent as ProviderIcon} from '../assets/provider.svg';
import { ReactComponent as CalendarIcon } from '../assets/calendar.svg';
import { ReactComponent as ClockIcon } from '../assets/clock.svg';
import { ReactComponent as LeftArrowCircle } from '../assets/left_arrow_circle.svg';
import { ReactComponent as Loading } from '../assets/loading.svg';
import { useAuth } from '../AuthContext';
import { BookingContext } from '../ConditionalRoute';
import { set } from 'lodash';
import Modal from './Modal';
import Toast from './Toast';
// import NoDoctorAvailable from './NoDoctorAvailable';


// TODO: centralize this
const meetingTypeMap = {
  'new': 'New Patient Mental Wellbeing',
  'followup': 'Follow Up Mental Wellbeing',
  '500961': 'New Patient Mental Wellbeing',
  '487240': 'Follow Up Mental Wellbeing',
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function pad2(number) {
  return (number < 10 ? '0' : '') + number
}

export default function MeetNowForm() {
  const {
    bookingInProgress,
    setBookingInProgress,
    bookingConfirmed,
  } = useContext(BookingContext);

  const location = useLocation();
  const navigate = useNavigate();
  const query = useQuery();
  const { currentUser, patientDetails, patientData, patientIsNewNew} = useAuth();
  const isFollowup = location.pathname.includes('followup');
  const isNewPatient = location.pathname.includes('book-appointment');
  const isFollowUpByState = location.pathname.includes('follow-up-by-state');

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = e => {
    e.preventDefault();

    // const selectedState = state;
    // const stateInForm = formData?.state;

    // if (isNewPatient && !_.isEmpty(stateInForm) && selectedState !== stateInForm && stateInForm !== undefined) {
    //   setIsModalOpen(true);
    // } else {
      onSubmit(e);
    // }
  };

  const closeModal = () => setIsModalOpen(false);

  const onModalCancel = e => {
    e.preventDefault();

    // query.set('state', formData.state);

    // navigate(`/book-appointment?${query.toString()}`, { replace: true, state: { formData } });
  };

  const [isEntering, setIsEntering] = useState(false);
  const [isExiting, setIsExiting] = useState(false);
  const [existingFormData, setExistingFormData]  = useState(location.state?.formData || {});
  console.log('location.state', location.state)
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    emailForm: '',
    DOBd: '',
    DOBm: '',
    DOBy: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    ...existingFormData,
    // meetingLocation: '',
  });
  console.log('existingFormData', existingFormData);
  console.log('formData', formData);
  const [fieldsToDisable, setFieldsToDisable] = useState([]);
  const [formDisabled, setFormDisabled] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [redirectTime, setRedirectTime] = useState(4);
  const [startRedirectTimer, setStartRedirectTimer] = useState(false);

  useEffect(() => {
    setIsEntering(true);
  }, []);

  useEffect(() => {
    let timer;

    if (startRedirectTimer && redirectTime > 0) {
      timer = setInterval(() => {
        setRedirectTime((prevTime) => prevTime - 1);
      }, 1000);
    }

    if (redirectTime === 0) {
      setStartRedirectTimer(false);
      clearInterval(timer);
    }

    return () => clearInterval(timer);
  }, [startRedirectTimer, redirectTime]);

  useEffect(() => {
    const maybeFillForm = async () => {
      if (currentUser) {
        const phoneNumber = formatPhoneNumber(currentUser.providerData[0].phoneNumber) || '';
        const uid = currentUser.uid;

        console.log('providerData', currentUser.providerData)
        // user.providerData[0] is trash
        console.log('data', patientData);

        let newDataToSet = {
            // ...formData,
            ...patientData?.firstName && { firstName: patientData?.firstName },
            ...patientData?.lastName && { lastName:  patientData?.lastName },
            ...phoneNumber && { phoneNumber},
            ...patientData?.emailForm && { emailForm: patientData?.emailForm },
            ...patientData?.DOBd && { DOBd: patientData?.DOBd?.toString() },
            ...patientData?.DOBm && { DOBm: patientData?.DOBm},
            ...patientData?.DOBy && { DOBy: patientData?.DOBy },
            ...patientData?.address1 && { address: patientData?.address1},
            ...patientData?.city && { city: patientData?.city},
            ...patientData?.state && { state: patientData?.state },
            ...patientData?.zipCode && {zipCode: patientData?.zipCode},
        };

        let dataToSet = {
          ...formData,
          ...newDataToSet,
        };

        console.log('dataToSet', dataToSet);

        const fieldsToDisable = Object.keys(newDataToSet).filter(fieldName => !!newDataToSet[fieldName]);
        console.log('fieldsToDisable', fieldsToDisable);
        setFieldsToDisable(fieldsToDisable);

        setFormData(dataToSet);

      } else {
        // User is signed out, clear the form fields
        // setFormData({});

      }
    }

    maybeFillForm();
  }, [currentUser, patientData, patientDetails]);

  useEffect(() => {
    if (!currentUser?.uid) {
      return;
    }

    registerMixpanelEvent({
      eventType: 'patientAuth',
      uid: currentUser.uid,
      data: {
        source: 'meetNow',
      }
    });

  }, [currentUser]);

  // const {
  //   calenderId,
  //   organizer,
  //   organizerName,
  //   room,
  //   start,
  //   end,
  //   type,
  //   state,
  //   timezone,
  // } = location.state?.timeslot;

  const [state, setState] = useState(query.get('state') || '');
  const [type, setType] = useState(query.get('type') || '500961');
  // default timezone to browser
  const [timezone, setTimezone] = useState(moment.tz.guess());


  const stateFullName = getStateFullName(state?.toUpperCase());
  const meetingTypeFullName = getMeetingTypeFullName(type);
  // const differenceInMinutes = moment(end).diff(moment(start), 'minutes');
  const stateCodes = Object.keys(stateCodeToName);
  const [errorTimer, setErrorTimer] = useState(null);

  const handleChange = e => {
    if (e.target.name.includes('DOB')) {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name.includes('phone')) {
      setFormData({
        ...formData,
        [e.target.name]: `+1${e.target.value.replace(/\D/g, '')}`,
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
    }
  };

  const handleEmailBlur = e =>{
    // setShowError(false);
    if (errorTimer) {
      clearTimeout(errorTimer)
    }
    const email = e.target.value.toLowerCase();

    const valid = isValidEmail(email);

    if (!valid) {
      setErrorMsg('Please enter a valid email address.');
      handleShowError();
    }
  };

  const handleShowError = () => {
    setShowError(true);
    const timer = setTimeout(() => {
      setShowError(false);
    }, 6000);


    setErrorTimer(timer);
  };

  const handleAddressChange = e => {
    if (e.target?.value || e.target?.value === '') {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
    } else {
      // bulk update from using google places api auto fill
      setFormData({
        ...formData,
        address: e.address,
        city: e.city,
        state: e.state,
        zipCode: e.zipCode,
      });
    }
  };


  const handleBack = e => {
    e.preventDefault();
    setIsExiting(true);
    setTimeout(() => {
      const searchParams = new URLSearchParams(location.search);
      let backPath = '';
      if (isFollowup) {
        backPath = `/followup?${searchParams.toString()}`;
      } else if (isFollowUpByState) {
        backPath = `/follow-up-by-state?${searchParams.toString()}`;
      } else {
        backPath = `/book-appointment?${searchParams.toString()}`;
      }

      navigate(backPath, { state: { formData } });
    }, 500);
  };

  const handleClearReschedule = () => {
    const locationState = location.state;

    delete locationState.reschedule;

    navigate(location.pathname, { replace: true, state: locationState});
  };

  // const selectedTimeslotIsAvailable = async () => {
  //   const selectedStartDate = moment(start).format('YYYY-MM-DD');

  //   const endpoint = 'https://us-central1-joyous-web.cloudfunctions.net/v2/getAvailableTimeSlots';
  //   const params = {
  //     state: state || '',
  //     timezone,
  //     startDate: selectedStartDate,
  //     endDate: moment(end).add(1, 'day').format('YYYY-MM-DD'),
  //     dr: organizer || '',
  //     appointmentType: meetingTypeFullName,
  //     redirect: 'https:/google.com/'
  //   };


  //   const response = await axios.get(endpoint, { params });

  //   if (response?.data.slots) {
  //     const slots = response.data.slots;
  //     const slotsForSelectedDate = slots[selectedStartDate];

  //     const foundTimeslot = _.find(slotsForSelectedDate, (timeslot) => {
  //       return timeslot.start === start && timeslot.organizer === organizer;
  //     });

  //     return !!foundTimeslot;
  //   } else {
  //     return false;
  //   }
  // };

  const onSubmit = async(e) => {
    e.preventDefault();
    closeModal();

    if (isLoading) {
      return;
    }

    setIsLoading(true);
    setFormDisabled(true);
    setShowError(false);
    setErrorMsg('');

    const isAuthenticated = !!auth.currentUser;

    if (!isAuthenticated) {
      handleNotAuthenticated(e);
      return;
    }

    const validEmail = isValidEmail(formData.emailForm);

    if (!validEmail) {
      setErrorMsg('Please enter a valid email address.');
      // setShowError(true);
      handleShowError();
      setIsLoading(false);
      setFormDisabled(false);
      return;
    }

    const validPhoneNumber = isValidPhoneNumber(formData.phoneNumber);

    if (!validPhoneNumber) {
      setErrorMsg('Please enter a valid phone number.');
      // setShowError(true);
      handleShowError();
      setIsLoading(false);
      setFormDisabled(false);
      return;
    }

    const anyFieldsEmpty = Object.values(formData).some(value => !value);

    if (anyFieldsEmpty) {
      console.log('emptyFields', formData);
      setErrorMsg('Please fill out all required fields.');
      // setShowError(true);
      handleShowError();
      setIsLoading(false);
      setFormDisabled(false);
      return;
    }

    // Make email all lower case
    setFormData({
      ...formData,
      emailForm: formData.emailForm.toLowerCase(),
    });

    const doesEmailExist = await checkIfEmailExists(formData.emailForm, auth.currentUser.uid, formData.phoneNumber);
    console.log('doesEmailExist', doesEmailExist)

    if (!doesEmailExist) {
      setErrorMsg('There was a problem checking your email. Please try again later.');
      // setShowError(true);
      handleShowError();
      setIsLoading(false);
      setFormDisabled(false);
      return;
    }

    if (doesEmailExist.exists) {
      setErrorMsg('Please use a different email.');
      // setShowError(true);
      handleShowError();
      setIsLoading(false);
      setFormDisabled(false);
      return;
    }

    // UPDATE PATIENT DETAIILS HERE
    const uid = auth.currentUser?.uid;
    const magicId = patientData?.magic_id;

    await updatePatientData(uid, {
      ...formData,
      meetNow: true,
      meetNowTs: new Date().getTime(),
      // meeting: start,
      intakeUrl: generateIntakeUrl(formData, magicId),
    });

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      em: formData.emailForm,
      ph: '1' + formData.phoneNumber.replace(/\D/g, ''),
      fn: formData.firstName,
      ln: formData.lastName,
      db: `${formData.DOBy}${pad2(formData.DOBm)}${pad2(formData.DOBd)}`,
      ct: formData.city,
      st: formData.state,
      zp: formData.zipCode,
      country: 'us',
    });

    window.gtag('event', 'purchase',{currency: "USD",value:150.00});
    // fbq('track', 'Purchase', {currency: "USD", value: 150.00});
    window.gtag_report_conversion();

    window.gtag('event', 'conversion', {
      'send_to': 'AW-10950760124/7Zb9CNmnleYDELyt3eUo',
      'transaction_id': ''
    });

    if (magicId) {
      window.gtag('event', 'book_magic', {
        id: magicId,
      });
    }

    registerMixpanelEvent({
      eventType: 'meetNowStart',
      uid,
    });


    console.log('Form submitted:', formData);
    setTimeout(() => {
      navigate(`/book-appointment${location.search}`, { state: { fromMeetNow: true} });
    }, 3000);

  };


  const handlePhoneFocus = e => {
    if (!auth.currentUser) {
      handleNotAuthenticated(e);
    }
  };

  const handleNotAuthenticated = e => {
    e.preventDefault(); // Prevent the default focus action
    const searchParams = new URLSearchParams(location.search);
    const isMeetNow = true;
    // If not authenticated, redirect to the login page// If not authenticated, redirect to the login page

    navigate(`/login?${searchParams.toString()}`, {
      state: {
        formData,
        isMeetNow,
      }
    });
  };

  return (

    <div
      className={`font-sans top-0 left-0 w-full h-full transition-opacity duration-300 ease-in-out ${
        isEntering && !isExiting ? 'opacity-100' : 'opacity-0 pointer-events-none'
      }`}
    >

      <div className="flex items-center justify-center p-4 sm-max:justify-start sm-max:items-stretch sm-max:p-0">
      <div
        className="bg-white p-14 pt-2 sm-max:p-8 pt-10 rounded-lg sm-max:shadow-none sm-max:w-full sm-max:h-full sm-max:overflow-y-auto w-full mt-28 sm-max:mt-0 shadow-[]"
        style={{
          maxWidth: '900px',
        }}
      >
        <div className="space-y-4">
          <div className="relative p-4">
          <Toast message={errorMsg} isVisible={showError} setIsVisible={setShowError} onClose={() => setShowError(false)} />
          </div>
          <div className="text-3xl text-center font-extrabold mb-4">{`Joyous ${stateFullName}`}</div>

          {bookingConfirmed &&
            <div className="text-center text-5xl font-extrabold text-blue-500">
              Confirmed
            </div>
          }

          <div className={!bookingConfirmed ? "mb-4 flex items-center" : "mb-4 flex items-center justify-center"}>
            {/* {!bookingConfirmed && (
              <div className="flex items-center text-blue-900 mb-2">
                <button onClick={e => handleBack(e)} className="relative top-[-47px]">
                  <LeftArrowCircle className="w-8 h-8 mr-3 font-bold text-gray"/>
                </button>
              </div>
            )} */}
            <div className="meeting-summary mt-2">
              <div className="flex items-center">
                <p className="text-2xl font-bold">{meetingTypeFullName}</p>
              </div>
              <div className="flex items-center">
                <ProviderIcon className="w-4 h-4 mr-2" />
                <p className="text-lg font-bold">Available Joyous Provider</p>
              </div>
              <div className="flex items-center">
                <CalendarIcon className="w-4 h-4 mr-2" />
                <p className="text-lg font-bold">{moment(new Date()).tz(timezone).format('dddd, MMMM D, YYYY')}</p>
              </div>
              <div className="flex items-center">
                <ClockIcon className="w-4 h-4 mr-2" />
                <p className="text-lg font-bold">{`Now`}</p>
              </div>
            </div>
          </div>

          {!bookingConfirmed &&(
              <form className="space-y-4">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <TextInput
                  name="firstName"
                  type="text"
                  label="Legal first name*"
                  placeholder="First name"
                  value={formData.firstName}
                  onChange={handleChange}
                  disabled={fieldsToDisable.includes('firstName') || formDisabled}
                />
                <TextInput
                  name="lastName"
                  type="text"
                  label="Legal last name*"
                  placeholder="Last name"
                  value={formData.lastName}
                  onChange={handleChange}
                  disabled={fieldsToDisable.includes('lastName') || formDisabled}
                />
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <PhoneInput
                  name="phoneNumber"
                  label="Cellphone*"
                  onChange={handleChange}
                  value={formData.phoneNumber}
                  className="flex-1 block w-full rounded-none" placeholder="Cellphone"
                  onFocus={handlePhoneFocus}
                  disabled={fieldsToDisable.includes('phoneNumber') || formDisabled}
                />

                <TextInput
                  name="emailForm"
                  type="text"
                  label="Email*"
                  placeholder="Email"
                  value={formData.emailForm}
                  onChange={handleChange}
                  onBlur={handleEmailBlur}
                  disabled={fieldsToDisable.includes('emailForm') || formDisabled}
                />

              </div>

              <div>
                <DateOfBirthInput
                  name="DOB"
                  label="Date of birth*"
                  onChange={handleChange}
                  value={{DOBd: formData.DOBd, DOBm: formData.DOBm, DOBy: formData.DOBy}}
                  disabled={fieldsToDisable.includes('DOBd') || formDisabled}
                />
              </div>

              <div>
                <AddressInput
                  name="addressData"
                  onChange={handleAddressChange}
                  value={
                    {
                      address: formData.address,
                      city: formData.city,
                      state: formData.state,
                      zipCode: formData.zipCode,
                    }
                  }
                  disabled={fieldsToDisable.includes('address') || formDisabled}
                />
              </div>


              <div>
                <button
                  type="submit"
                  className={`w-full bg-purple text-white text-xl font-black p-4 rounded-md mt-6 flex items-center justify-center ${
                    isLoading ? 'bg-purple cursor-not-allowed' : 'hover:bg-purple'
                  }`}
                  disabled={isLoading}
                  onClick={e => openModal(e)}
                >
                  {isLoading ? (
                    <Loading/>
                  ) : (
                    'Meet With a Provider Now'
                  )}
                </button>
              </div>
            </form>
          )}

          {bookingConfirmed && (
            <div>
              <div className="flex text-center justify-center mx-auto max-w-96 mt-6 font-extrabold text-blue-500 text-2xl">
                The invitation has been sent to your email address
              </div>


              <div className="mt-8 max-w-96 bg-blue-50 text-center p-4 rounded-md justify-center mx-auto">
                <p className="font-extrabold text-gray-700 text-2xl">
                  You will be redirected to answer few questions needed for your Dr. appointment
                </p>
                <p className="text-2xl font-extrabold mt-2">{redirectTime}...</p>
              </div>
            </div>

          )}
        </div>
      </div>
    </div>


    <Modal
      isOpen={isModalOpen}
      message={`You have selected **${stateFullName}** for your appointment, but your address is listed as **${getStateFullName(formData.state)}**. To ensure your appointment is booked correctly, please choose the state where you will be during the meeting`}
      title="Different State"
      proceedText={`I will be in ${stateFullName}`}
      cancelText={`I will be in ${getStateFullName(formData.state)}`}
      onCancel={onModalCancel}
      onClose={closeModal}
      onProceed={e => onSubmit(e)}
    />

  </div>
  );
}
