import React, { useEffect } from 'react';
// import firebase from 'firebase/app';
import firebase from 'firebase/compat/app';
import { auth, PhoneAuthProvider } from '../utils/firebase';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import { useLocation, useNavigate } from 'react-router-dom';

const FirebaseAuth = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const timeslotState = location.state?.timeslot;
  const formData = location.state?.formData;
  const isSelectFollowUpType = location.state?.isSelectFollowUpType;
  const isFollowUpByState = location.state?.isFollowUpByState;
  const isMeetNow = location.state?.isMeetNow;
  const hasDxOrDr = location.search.includes('dx') || location.search.includes('dr');

  console.log('isMeetNow', isMeetNow);

  useEffect(() => {
    const uiConfig = {
      signInSuccessUrl: '/form', // URL to redirect to after a successful sign-in
      signInOptions: [
        {
          provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
          recaptchaParameters: {
            type: 'image',
            size: 'invisible',
            badge: 'bottomleft'
          },
        },
      ],
      callbacks: {
        signInSuccess: function(currentUser, credential, redirectUrl) {
          const userId = currentUser.uid;
          console.log("signInSuccess " +userId);
          console.log(currentUser);
          // Manually redirect.
          //window.location.assign(`/users/${userId}`);
          console.log('isMeetNow', isMeetNow);

          if (isMeetNow) {
            navigate(`/meet-now/form${location.search}`, { state: { formData} });
          } else if (isFollowUpByState && timeslotState) {
            navigate(`/follow-up-by-state/form${location.search}`, { state: { timeslot: timeslotState, formData} });
          } else if (isSelectFollowUpType) {
            navigate(`/select-follow-up-type${location.search}`);
          } else if (hasDxOrDr && timeslotState) {
            navigate(`/followup/form${location.search}`, { state: { timeslot: timeslotState, formData} });
          } else if (hasDxOrDr) {
            navigate(`/followup${location.search}`);
          } else if (timeslotState) {
            navigate(`/book-appointment/form${location.search}`, { state: { timeslot: timeslotState, formData} });
          } else {
            navigate(`/book-appointment${location.search}`);
          }
          return false;
        },
        signInFailure: function(error) {
          console.log("signInFailure");
          console.log(error);
          return Promise.resolve();
        },
        uiShown: function() {
          console.log('FirebaseUI widget is rendered');
        }
      }
      // Other config options...
    };

    const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);

    ui.start('#firebaseui-auth-container', uiConfig);
  }, []);

  return (
    <div>
      <h2></h2>
      <div id="firebaseui-auth-container"></div>
    </div>
  );
};

export default FirebaseAuth;
