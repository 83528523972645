import './App.css';
import { BrowserRouter as
  Router,
  Route,
  Routes,
  Link,
  Navigate,
  useLocation
} from 'react-router-dom';

import TimeslotPicker from './components/TimeslotPicker';
import TimeslotForm from './components/TimeslotForm';
import MeetNowForm from './components/MeetNowForm';
import FirebaseAuth from './components/FirebaseAuth';
import RequireTimeslot from './RequireTimeslot';
import RequireParams from './RequireParams';
import AlreadyBooked from './components/AlreadyBooked';
import CompleteIntake from './components/CompleteIntake';
import SelectState from './components/SelectState';
import { AuthProvider } from './AuthContext';
import ConditionalRoute from './ConditionalRoute';
import SelectFollowUpType from './components/SelectFollowUpType';

function App() {

  return (
    <div className='Joyous Booking'>
      <Router>
        <AuthProvider>
          <Routes>
            <Route element={<ConditionalRoute />}>
              <Route element={<RequireParams/>}>
                {/* <Route path='/' element={<Navigate to='/book-appointment' />} /> */}
                <Route path='/book-appointment' element={<TimeslotPicker/>} />
                {/* Conditional Route */}
                  <Route path="/book-appointment/form" element={
                    <RequireTimeslot>
                      <TimeslotForm />
                    </RequireTimeslot>
                  } />
                  <Route path="/followup/form" element={
                    <RequireTimeslot>
                      <TimeslotForm />
                    </RequireTimeslot>
                  } />

                  <Route path="/follow-up-by-state/form" element={
                    <RequireTimeslot>
                      <TimeslotForm />
                    </RequireTimeslot>
                  } />

                  <Route path="/meet-now/form" element={
                    <MeetNowForm />
                  } />
                <Route path='/login' element={<FirebaseAuth />} />
                <Route path='/followup' element={<TimeslotPicker/>} />
                <Route path='/follow-up-by-state' element={<TimeslotPicker/>} />
                <Route path='/select-follow-up-type' element={<SelectFollowUpType/>} />
              </Route>

              {/* <Route path="/select-state" element={<SelectState/>} /> */}
            </Route>
            <Route path="/complete-intake" element={<CompleteIntake/>} />
            <Route path="/already-booked" element={<AlreadyBooked/>} />
            <Route path='/' element={<Navigate to='/select-state' />} />
            <Route path="/select-state" element={<SelectState/>} />
          </Routes>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
